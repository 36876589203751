var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('AError',{attrs:{"api":_vm.apiGetJob}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.alertError.length > 0)?_c('v-alert',{staticClass:"text-pre-wrap",attrs:{"dense":"","border":"left","type":"warning"}},_vm._l((_vm.alertError),function(error,index){return _c('span',{key:index,staticClass:"d-block"},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-card',[_c('v-toolbar',{attrs:{"color":this.hrdcData.HRDC_BLUE_THEME_COLOR}},[_c('v-btn',{staticClass:"hidden-xs-only text-white",attrs:{"icon":""},on:{"click":function($event){return _vm.redirectBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(!_vm.loadingDialog)?_c('v-toolbar-title',[_c('div',{staticClass:"d-flex align-center text-white"},[_vm._v(" "+_vm._s(_vm.model.add.name)+" ")])]):_vm._e(),_c('v-spacer'),(_vm.hrdcData.isHrdcSuperAdminRoles)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.manuallyExecuteCron()}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cog-play ")])],1)]}}],null,false,1261114654)},[_c('span',[_vm._v("Execute Cron Job")])])],1):_vm._e()],1),_c('div',[_c('div',{staticClass:"pa-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.jobDatas,"items-per-page":10},scopedSlots:_vm._u([{key:"item.err",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.err)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.revertAction(item)}}},[_vm._v(" mdi-undo ")])]}}])})],1)])],1),_c('HrdcLoader',{attrs:{"loadingDialog":_vm.loadingDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }