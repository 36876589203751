<template>
  <v-container fluid>
    <AError :api="apiGetJob" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-card>
      <v-toolbar :color="this.hrdcData.HRDC_BLUE_THEME_COLOR">
        <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.add.name }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span v-if="hrdcData.isHrdcSuperAdminRoles">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="white"
                class="hidden-xs-only"
                @click="manuallyExecuteCron()"
              >
                <v-icon dark v-bind="attrs" v-on="on"> mdi-cog-play </v-icon>
              </v-btn>
            </template>
            <span>Execute Cron Job</span>
          </v-tooltip>
        </span>
      </v-toolbar>

      <div>
        <div class="pa-5">
          <v-data-table
            :headers="headers"
            :items="jobDatas"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item.err="{ item }">
              <div v-html="item.err"></div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="revertAction(item)"> mdi-undo </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetJob: new Api(),
    apiRevertJob: new Api(),
    apiManualExecJob: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    errorField: {
      applicationNo: null,
    },
    loadingDialog: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
      },
      { text: "Description", value: "description", sortable: false },
      { text: "Execute Date", value: "timestampCreated", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    jobDatas: [],
  }),
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetJob: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/job/get-job`,
      },
      apiManualExecJob: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/check-expired-grants`,
      },
      apiRevertJob: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/job/revert-job`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    this.hrdcData.rolesAssignation(this.auth.roles);
    this.jobDatas = [];

    this.apiGetJob.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;

        if (!status) {
          this.$store.dispatch("showMessage", {
            message: data,
            messageType: "error",
            timeout: 2000,
          });

          return;
        }

        if (status) {
          if (data) {
            let index = 1;
            for (const item of data) {
              const { id, description, timestampCreated } = item;

              this.jobDatas.push({
                id,
                index,
                description,
                timestampCreated: moment(timestampCreated).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              });
              index++;
            }
          }
        }
        this.hideLoadingDialog();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetJob.fetch();

    this.hideLoadingDialog();
  },
  methods: {
    redirectBack() {
      let route = "HrdcSettings";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    manuallyExecuteCron() {
      this.showLoadingDialog();

      this.apiManualExecJob.setHeaders({
        Authorization: process.env.VUE_APP_API_KEY,
      });

      this.apiManualExecJob.setCallbackCompleted(() => {
        this.jobDatas = [];
        this.apiGetJob.fetch();

        this.$store.dispatch("showMessage", {
          message: "Job Executed Successfully!",
          messageType: "success",
          timeout: 2000,
        });

        this.hideLoadingDialog();
      });

      this.apiManualExecJob.fetch();
    },
    revertAction(item) {
      this.showLoadingDialog();

      this.apiRevertJob.setParams({
        type: 1, // 1=DELETE EXPIRED CRON
        id: item.id,
      });

      this.apiRevertJob.setCallbackCompleted(() => {
        this.$store.dispatch("showMessage", {
          message: "Job Executed Successfully!",
          messageType: "success",
          timeout: 2000,
        });

        this.hideLoadingDialog();
      });

      this.apiRevertJob.fetch();
    },
  },
};
</script>
